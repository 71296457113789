@import "././site/site_variables";
/*
------------------------------------------------------------------------------------- NAVIGATION

------------------------------------------------------------------------------------------------
*/
#desktop-search {
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
.js .icon-menu {
  display: block;
  padding: 0;
  background-color: transparent;
  border: none;
  line-height: 39px;
  font-size: 1em;
  text-transform: none;
  /*overflow:hidden; text-indent:-300px;*/
  float: right;
  width: 39px;
  overflow: hidden;
  word-wrap: normal; /* WORD WRAP IS IMPORTANT!! */
  white-space: nowrap;
  text-indent: 101%;
}
.icon-menu:before {
  width: 35px;
  height: 35px;
  margin: 0;
  position: absolute;
  top: 1px;
  right: 2px;
  content: "";
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Crect x='9.7' y='12.2' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='27.1' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='41.9' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='12.2' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='27.1' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3Crect x='9.7' y='41.9' style='fill:%23FFFFFF' width='41.6' height='5.9'/%3E%3C/svg%3E");
}

.icon-menu:after {
  background-image: none;
}
.mob-nav-open .icon-menu:before {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpolygon style='fill:%23FFFFFF' points='34.2,30 48.1,43.9 43.9,48.1 30,34.1 16.1,48.1 11.9,43.9 25.9,30 11.9,16.1 16.1,11.9 30,25.8 43.9,11.9 48.1,16.1 '/%3E%3C/svg%3E");
}

.main-nav {
  background-color:#e8eaec;
  clear: both;
  @media screen and (max-width: 833px) {
    top:10px;
  }
}
.main-nav ul {
  margin: 0 !important;
  padding: 0;
  font-size: .9em;
  list-style-type: none;
}
.main-nav ul ul {
  font-size: .9em;
  background-color: #e8eaec;
}
.main-nav li {
  padding: 0;
}
.main-nav a {
  display: block;
  padding: 14px 30px 12px 12px;
  color: $bg-swoop;
  text-transform: uppercase;
  text-decoration: none;
  position: relative;
}
.main-nav a:hover,
.main-nav a.active {
  background-color: $bg-grey-pale;
}

.js .main-nav {
  max-height: 0;
  transition: max-height 0.4s ease-out, height 0.4s ease-out;
}
.js .mob-nav-open .header-inner .main-nav {
  max-height: none !important;
  z-index: 1;
  position: relative;
}

.js .main-nav ul {
  position: relative;
  transition: left 0.3s ease-out;
}

.overlay {
  z-index: 50;
  position: fixed;
  width: 100%;
  -webkit-backface-visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  background-color: #1c1a1b;
}

.overlay-visible {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.8;
}
.overlay-hidden {
  opacity: 0;
}

/* If you have a sub navigation */

.js .main-nav ul ul {
  display: none;
  position: absolute;
  width: 100%;
  top: 0;
  left: 100%;
}

.js .menu-pos-0 {
  left: 0;
}
.js .menu-pos-1 {
  left: -100%;
}
.js .menu-pos-2 {
  left: -200%;
}
.js .menu-pos-3 {
  left: -300%;
}
.js .menu-pos-4 {
  left: -400%;
}

.js .icon-sub-back {
  opacity: 0.8;
}
.js .icon-sub-back:hover {
  opacity: 1;
}

.js .icon-sub::after {
  content: "ᐅ";
  color: inherit;
  position: absolute;
  top: 18px;
  right: 15px;
  width: 7px;
  height: 12px;
  @media screen and (min-width: 833px) {
    content: "";
    background-repeat: no-repeat;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23ffffff;' d='M30,37.9L16.4,22.1h27.3L30,37.9z M20.7,24.1L30,34.8l9.3-10.7H20.7z'/%3E%3C/svg%3E%0A");
    right: 0;
    top: 16px !important;
    width: 27px !important;
    height: 30px !important;
  }
}
.primary-nav > li > ul a.icon-sub::after{
  background-image: none;
  width: 7px !important;
  height: 12px !important;
}

.js .icon-sub-back::before {
  content: "ᐊ";
  color: inherit;
  float: left;
  width: 7px;
  margin-top: 0;
  margin-right: 10px;
}

/*
------------------------------------------------------------------------------------ COUNTRY NAV

------------------------------------------------------------------------------------------------
*/

.country-drop {
  position: relative;
  float: right;
}

.country-drop ul {
  background-color: $bg-grey-paler;
  margin: 0;
  padding-top: 56px;
  position: absolute;
  top: -4px;
  left: 0;
  z-index: 100;
  overflow: hidden;
  border-radius: 4px;
  transition: opacity 0.3s ease-out;
}
.country-drop li {
  padding: 0;
  height: 48px;
  transition: opacity 0.3s ease-out;
}
.country-drop .flag-selected {
  // height: 48px;
  position: relative;
  z-index: 101;
  cursor: pointer;
  opacity: 0.9;
  filter:grayscale(65%);
  transform: scale(0.95);
  transition: all 0.3s ease-out;
  &:hover, &:focus {
    transform: scale(1);
    filter:none;
    opacity: 1;
  }
}

/* open close */

.country-drop-show ul {
  height: auto;
  opacity: 1;
  box-shadow: 3px 3px 7px -2px rgba(0, 0, 0, 0.4);
}
.country-drop-hide ul {
  opacity: 0;
}
.country-drop li.flag-show {
  &:nth-child(1) {
    animation: delayFadeIn 200ms;
    -webkit-animation: delayFadeIn 200ms;
  }
  
  &:nth-child(2) {
    animation: delayFadeIn 250ms;
    -webkit-animation: delayFadeIn 250ms;
  }
  
  &:nth-child(3) {
    animation: delayFadeIn 300ms;
    -webkit-animation: delayFadeIn 300ms;
  }
  
  &:nth-child(4) {
    animation: delayFadeIn 350ms;
    -webkit-animation: delayFadeIn 350ms;
  }
  
  &:nth-child(5) {
    animation: delayFadeIn 400ms;
    -webkit-animation: delayFadeIn 400ms;
  }
  
  &:nth-child(6) {
    animation: delayFadeIn 450ms;
    -webkit-animation: delayFadeIn 450ms;
  }
  
  &:nth-child(7) {
    animation: delayFadeIn 500ms;
    -webkit-animation: delayFadeIn 500ms;
  }
}
// fade in css
@-webkit-keyframes delayFadeIn {
  0% {opacity: 0;}
  50% {opacity:0;}
  100% {opacity: 1;}
}

@keyframes delayFadeIn {
  0% {opacity: 0;}
  50% {opacity:0;}
  100% {opacity: 1;}
}
a.icon-flag, span.icon-flag{
  margin: 0 0 -8px;
}
a.icon-flag {
  opacity: 0.5;
  transform: scale(0.9);
  transition: opacity 0.3s ease-out, transform 0.3s;
  &:hover {
    opacity:1;
    transform: scale(1);
  }
}

/*
------------------------------------------------------------------------------------ CONTENT NAV

------------------------------------------------------------------------------------------------
*/

.content-nav {
  display: none;
}
.content-nav {
  @include clrfix;
  padding: .5em 0;
  background-color: rgb(28, 38, 96);
}
.content-nav-crumbs {
  list-style-type: none;
  margin: 0;
  font-size: 0.9em;
}
.content-nav-crumbs li {
  float: left;
  position: relative;
  padding: 0 9px 0 9px;
  margin-right: 4px;
}
.content-nav-crumbs li:after {
  content: "";
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 60'%3E%3Cpath style='fill:%23FFFFFF' d='M40.1,31.4L24.5,46.9c-0.8,0.8-2,0.8-2.7,0L20,45.1c-0.8-0.8-0.8-2,0-2.7L32.3,30L20,17.6c-0.7-0.8-0.7-2,0-2.7l1.8-1.8 c0.8-0.8,2-0.8,2.7,0l15.6,15.5C40.8,29.4,40.8,30.6,40.1,31.4L40.1,31.4z'/%3E%3C/svg%3E");
  opacity: 0.6;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 2px;
  right: -10px;
}
.content-nav-crumbs li:last-child {
  padding-right: 0;
}
.content-nav-crumbs li:last-child:after {
  display: none;
}
.content-nav-crumbs a {
  color: $white;
}

.content-nav--mobile {

  &.-no-absolute {
    position: initial;
  }

  position: absolute;
  width: 100%;
  display: block;
  background-color: rgb(28, 38, 96);
  z-index: 11;
  ul {
    // prevents mobile breadcrumb overflow
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  li {
    display: none;
    &:last-of-type {
      display: inline-block;
    }
    &:nth-last-of-type(2) {
      display: inline-block;
    }
  }
  li:nth-last-child(1) {
    &::after {
      content: "";
      display: inline-block;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAUCAYAAAGIQU8tAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyNpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuNi1jMTQ4IDc5LjE2NDAzNiwgMjAxOS8wOC8xMy0wMTowNjo1NyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6QzVFREM0RkUwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6QzVFREM0RkQwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIDIwMjAgTWFjaW50b3NoIj4gPHhtcE1NOkRlcml2ZWRGcm9tIHN0UmVmOmluc3RhbmNlSUQ9InhtcC5paWQ6QzVFREM0RjkwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiIHN0UmVmOmRvY3VtZW50SUQ9InhtcC5kaWQ6QzVFREM0RkEwRUNEMTFFQTk5QzQ5NzMwMTQwRjY2OTUiLz4gPC9yZGY6RGVzY3JpcHRpb24+IDwvcmRmOlJERj4gPC94OnhtcG1ldGE+IDw/eHBhY2tldCBlbmQ9InIiPz4yDFokAAACCElEQVR42mKUNgm3ZmBgMANieyC2AGIRJiDByIAGmJDYcEmAAGIEag8F0lZA7AzEykDMhazyPzbtcAAQQCDtNkBaEohlgFgOiBWhWByIeYGYHYiZmZCNwgcAAghkogeQloZiJSgGmS4EchzINJBzYCYyEjIRFhj/0X2ETeF/JAWMuExnQjMZp6kAAcQIjRweIOYHYj5okPBBsTAQCwCxIBTzQD3IAcSsUAyzhAk9Ev6Bbf3PxICsAMnpRAU3E5pfGRkY/zFgCQeiDEM2EF0DI1pYMRKKDWwuZCAiGgmmB4AAlJTRCsAgCEW5DnoZDNYn7f+/SUfMh3Ba+hCESOU99zagPCr2ZaDc6rKuwMY6FUpTIMcEBbNv4IzrjbwdmxJa2RoqUGSRHWRf6O2lYpWIMps6Za3ihdkCYXPB359C5W8kEv/rAac0xObAUlIkSAsqWr4CtFpFKxDDIGwRbtv9///We7GHiNH1rgNZxxA0JrHTvi5j/uViWtRt36e93y7ucJ55p4uXU9VUVlQYmCFL+BnhkgKdbo44NjzV2tOF2eqK2leqj6yOtlO5bDYWedDsMwKaDKXIi02OBl0t1gdYARziQVUHS2ZbIUNzEP5xxRJfqcwfX05iIBmxEo4pEdjPfs9E0o2ounKBcBQ7CswW+pGMzTcgCbpImt2CYCxAGrTiIkRzL/jLEz+PDHnKFrHw6wAAAABJRU5ErkJggg==")
        0 0 no-repeat;
      width: 40px;
      height: 20px;
      right: 0px;
    }
  }
}

// RESPONSIVE ---------------
// (min-width: $break-medium) removed 
@media screen and (min-width: 833px) {
  //  720/16
  /* ---------------------- NAVIGATION */
  .header-nav {
    clear: both;
    @include clrfix;
    border-top: 2px solid $white;
  }
  .js .main-nav,
  .main-nav {
    max-height: none;
    overflow: visible;
    clear: none;
    background-color: transparent;
  }
  .js .icon-menu,
  .icon-menu {
    display: none;
  }

  .main-nav {
    padding-top: 0;
    float: left;
    width: auto;
  }
  .main-nav ul {
    @include clrfix;
    float: left;
  }

  .main-nav ul,
  .js .main-nav ul {
    transition: none;
    @include clrfix;
  }

  .main-nav li {
    float: left;
    position: relative;
  }
  .main-nav a {
    padding: 20px 30px 16px 20px;
    color: $white;
  }

  .main-nav a:hover,
  .main-nav > ul > li:hover > a {
    background-color: $white;
    color: $bg-swoop;
  }

  .js .icon-sub:after {
    width: 12px;
    height: 7px;
    top: 20px;
  }
  .js .icon-sub > a:after {
    display: none;
  }

  .js .main-nav ul ul,
  .main-nav ul ul {
    background-color: $white;
    background-color: rgba(255, 255, 255, 0.95);
    font-size: 1em;
    display: none;
    width: 216px;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 500;
  }

  .main-nav li:hover > ul {
    display: block;
  }

  .main-nav li.show-sub > ul,
  .js .main-nav li.show-sub > ul {
    display: block;
  }

  .main-nav ul ul li {
    float: none;
    margin-left: 0;
  }

  .main-nav ul ul ul,
  .js .main-nav ul ul ul {
    position: absolute;
    left: 100%;
    top: 0;
  }

  .js .main-nav ul ul a,
  .main-nav ul ul a {
    color: $bg-swoop;
    text-transform: none;
    padding-top: 14px;
    padding-bottom: 10px;
  }

  .js .main-nav ul ul .icon-sub:after,
  .main-nav ul ul .icon-sub:after {
    content: "ᐅ";
    width: 7px;
    height: 12px;
    top: 14px;
  }

  .main-nav ul ul a:hover,
  .main-nav ul ul li:hover > a {
    background-color: $bg-grey-paler;
  }

  .js .main-nav ul ul .icon-sub,
  .main-nav ul ul .icon-sub {
    padding-right: 18px;
  }

  .js .main-nav ul ul .icon-sub:after,
  .main-nav ul ul .icon-sub:after {
    right: 10px;
  }

  .main-nav .icon-sub:hover ul {
    display: block;
  }
  .js .main-nav .icon-sub:hover ul {
    display: none;
  }

  .js .main-nav ul .js-nav-open ul,
  .js .main-nav .js-nav-open:hover ul {
    display: block;
  }

  .main-nav li:hover > ul {
    display: block;
  }

  .nav-aside {
    float: right;
    position: relative;
  }

  .content-nav {
    display: block;
  }

  .content-nav--mobile {
    display: none;
  }

  .float-header .header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }

  .float-header .header-inner,
  .float-header .content-nav {
    background: transparent;
  }

  .float-header .banner-inner {
    padding-top: 343px; // 90 + height of header
  }

  .float-header .banner-simple {
    padding-top: 220px; // 
  }

  .float-header .header-nav {
    min-height: 4rem;
    border-bottom: 2px solid #fff;
  }

}
// Table NAV CTA fix
@media (max-width: 834px) {
  .mobile-expand {
    width: 100%;
    padding-bottom: 0.5rem;
    margin-bottom: .5rem;
    border-bottom: 1px solid #2c5282;
  }
  .desktop__hide {
    display: none;
  }
  .mobile__show {
    display: block;
  }
}
@media screen and (max-width: 1000px) {
  .main-nav a {
    padding: 20px 25px 16px 15px;
    font-size: 0.9rem;
  }
}
.nav-edit {
  font-size: 0.75em;
  padding: 0;
  border-radius: 5px;
  display: inline-block;
  position: relative;
}

/**
 * ----------------------------------------
 * animation swing-in-top-fwd
 * ----------------------------------------
 */
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}



