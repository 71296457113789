.icon-amex:before {
  content: "\e900";
  color: #fff;
}
.icon-mastercard .path1:before {
  content: "\e901";
  color: rgb(255, 255, 255);
}
.icon-mastercard .path2:before {
  content: "\e902";
  margin-left: -1.67578125em;
  color: rgb(255, 95, 0);
}
.icon-mastercard .path3:before {
  content: "\e903";
  margin-left: -1.67578125em;
  color: rgb(235, 0, 27);
}
.icon-mastercard .path4:before {
  content: "\e904";
  margin-left: -1.67578125em;
  color: rgb(247, 158, 27);
}
.icon-visa .path1:before {
  content: "\e905";
  color: rgb(255, 255, 255);
}
.icon-visa .path2:before {
  content: "\e906";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-visa .path3:before {
  content: "\e907";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-visa .path4:before {
  content: "\e908";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-visa .path5:before {
  content: "\e909";
  margin-left: -1.67578125em;
  color: rgb(26, 31, 113);
}
.icon-facebook:before {
  content: "\e90a";
}
.icon-instagram:before {
  content: "\e90b";
}
.icon-linkedin:before {
  content: "\e90c";
}
.icon-pinstrast:before {
  content: "\e90d";
}
.icon-tweeter-x:before {
  content: "\e90e";
}
.icon-tweeter:before {
  content: "\e90f";
}
.icon-whats-app:before {
  content: "\e910";
}
.icon-youtube:before {
  content: "\e911";
}
.icon-a-b:before {
  content: "\e912";
}
.icon-alert:before {
  content: "\e913";
}
.icon-ambulance:before {
  content: "\e914";
}
.icon-anchour:before {
  content: "\e915";
}
.icon-arrow-d:before {
  content: "\e916";
}
.icon-arrow-down:before {
  content: "\e917";
}
.icon-arrow-left:before {
  content: "\e918";
}
.icon-arrow-r-top:before {
  content: "\e919";
}
.icon-arrow-r:before {
  content: "\e91a";
}
.icon-arrow-right:before {
  content: "\e91b";
}
.icon-arrow-u:before {
  content: "\e91c";
}
.icon-base-camp-lux:before {
  content: "\e91d";
}
.icon-base-camp:before {
  content: "\e91e";
}
.icon-bcorp:before {
  content: "\e91f";
}
.icon-bear:before {
  content: "\e920";
}
.icon-bed:before {
  content: "\e921";
}
.icon-bed2:before {
  content: "\e922";
}
.icon-bird:before {
  content: "\e923";
}
.icon-build:before {
  content: "\e924";
}
.icon-bus-guide:before {
  content: "\e925";
}
.icon-bus:before {
  content: "\e926";
}
.icon-camping:before {
  content: "\e927";
}
.icon-car-group:before {
  content: "\e928";
}
.icon-car:before {
  content: "\e929";
}
.icon-carbon-off:before {
  content: "\e92a";
}
.icon-carbon:before {
  content: "\e92b";
}
.icon-celebration:before {
  content: "\e92c";
}
.icon-chat:before {
  content: "\e92d";
}
.icon-chevron-down:before {
  content: "\e92e";
}
.icon-chevron-r:before {
  content: "\e92f";
}
.icon-chevron-right:before {
  content: "\e930";
}
.icon-chevron2:before {
  content: "\e931";
}
.icon-choice-PAT:before {
  content: "\e932";
}
.icon-choice:before {
  content: "\e933";
}
.icon-church:before {
  content: "\e934";
}
.icon-climbing:before {
  content: "\e935";
}
.icon-close:before {
  content: "\e936";
}
.icon-cloude:before {
  content: "\e937";
}
.icon-confused:before {
  content: "\e938";
}
.icon-covid:before {
  content: "\e939";
}
.icon-cup:before {
  content: "\e93a";
}
.icon-cx-skiing:before {
  content: "\e93b";
}
.icon-date:before {
  content: "\e93c";
}
.icon-dog-sledding:before {
  content: "\e93d";
}
.icon-dont-know:before {
  content: "\e93e";
}
.icon-door:before {
  content: "\e93f";
}
.icon-drink:before {
  content: "\e940";
}
.icon-ecocamp:before {
  content: "\e941";
}
.icon-email-2:before {
  content: "\e942";
}
.icon-email:before {
  content: "\e943";
}
.icon-express:before {
  content: "\e944";
}
.icon-extension:before {
  content: "\e945";
}
.icon-extension2:before {
  content: "\e946";
}
.icon-eye:before {
  content: "\e947";
}
.icon-fishing:before {
  content: "\e948";
}
.icon-food:before {
  content: "\e949";
}
.icon-glacier-trekking:before {
  content: "\e94a";
}
.icon-glass:before {
  content: "\e94b";
}
.icon-globe:before {
  content: "\e94c";
}
.icon-green:before {
  content: "\e94d";
}
.icon-guide:before {
  content: "\e94e";
}
.icon-gym:before {
  content: "\e94f";
}
.icon-hand:before {
  content: "\e950";
}
.icon-hauled-sled:before {
  content: "\e951";
}
.icon-helicopter:before {
  content: "\e952";
}
.icon-history:before {
  content: "\e953";
}
.icon-horse-riding:before {
  content: "\e954";
}
.icon-hot-tub:before {
  content: "\e955";
}
.icon-hotel:before {
  content: "\e956";
}
.icon-ice:before {
  content: "\e957";
}
.icon-image .path1:before {
  content: "\e958";
  color: rgb(0, 0, 0);
}
.icon-image .path2:before {
  content: "\e959";
  margin-left: -1em;
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.icon-image .path3:before {
  content: "\e95a";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-image .path4:before {
  content: "\e95b";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-info:before {
  content: "\e95c";
}
.icon-kayaking:before {
  content: "\e95d";
}
.icon-length:before {
  content: "\e95e";
}
.icon-light:before {
  content: "\e95f";
}
.icon-lock:before {
  content: "\e960";
}
.icon-lux_hotel:before {
  content: "\e961";
}
.icon-map-marker:before {
  content: "\e962";
}
.icon-map:before {
  content: "\e963";
}
.icon-market:before {
  content: "\e964";
}
.icon-menu:before {
  content: "\e965";
}
.icon-mountain-biking:before {
  content: "\e966";
}
.icon-mountain-peak:before {
  content: "\e967";
}
.icon-mountaineering:before {
  content: "\e968";
}
.icon-move:before {
  content: "\e969";
}
.icon-multi:before {
  content: "\e96a";
}
.icon-museum:before {
  content: "\e96b";
}
.icon-paddle-boarding:before {
  content: "\e96c";
}
.icon-paddling:before {
  content: "\e96d";
}
.icon-payment:before {
  content: "\e96e";
}
.icon-penguin:before {
  content: "\e96f";
}
.icon-phone:before {
  content: "\e970";
}
.icon-photography:before {
  content: "\e971";
}
.icon-plain-2:before {
  content: "\e972";
}
.icon-plain:before {
  content: "\e973";
}
.icon-play .path1:before {
  content: "\e974";
  color: rgb(0, 0, 0);
  opacity: 0.6;
}
.icon-play .path2:before {
  content: "\e975";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-play .path3:before {
  content: "\e976";
  margin-left: -1em;
  color: rgb(255, 255, 255);
}
.icon-plus:before {
  content: "\e977";
}
.icon-price:before {
  content: "\e978";
}
.icon-print:before {
  content: "\e979";
}
.icon-puma:before {
  content: "\e97a";
}
.icon-question-2:before {
  content: "\e97b";
}
.icon-rafting:before {
  content: "\e97c";
}
.icon-refugio:before {
  content: "\e97d";
}
.icon-relax:before {
  content: "\e97e";
}
.icon-research-PAT:before {
  content: "\e97f";
}
.icon-research:before {
  content: "\e980";
}
.icon-road-trip:before {
  content: "\e981";
}
.icon-sailing:before {
  content: "\e982";
}
.icon-sauna:before {
  content: "\e983";
}
.icon-science:before {
  content: "\e984";
}
.icon-scuba:before {
  content: "\e985";
}
.icon-seal:before {
  content: "\e986";
}
.icon-search:before {
  content: "\e987";
}
.icon-sextant:before {
  content: "\e988";
}
.icon-shake:before {
  content: "\e989";
}
.icon-ship-2:before {
  content: "\e98a";
}
.icon-ship-lg:before {
  content: "\e98b";
}
.icon-ship-md:before {
  content: "\e98c";
}
.icon-ship:before {
  content: "\e98d";
}
.icon-sign:before {
  content: "\e98e";
}
.icon-skiing:before {
  content: "\e98f";
}
.icon-snorkelling:before {
  content: "\e990";
}
.icon-snow-shoeing:before {
  content: "\e991";
}
.icon-snow:before {
  content: "\e992";
}
.icon-snowmobiling:before {
  content: "\e993";
}
.icon-speach:before {
  content: "\e994";
}
.icon-stamp2:before {
  content: "\e995";
}
.icon-star-full:before {
  content: "\e996";
}
.icon-star-half:before {
  content: "\e997";
}
.icon-star:before {
  content: "\e998";
}
.icon-submarine:before {
  content: "\e999";
}
.icon-suitcase:before {
  content: "\e99a";
}
.icon-sun:before {
  content: "\e99b";
}
.icon-swoop:before {
  content: "\e99c";
}
.icon-taxi:before {
  content: "\e99d";
}
.icon-team:before {
  content: "\e99e";
}
.icon-temp:before {
  content: "\e99f";
}
.icon-thanks:before {
  content: "\e9a0";
}
.icon-tick:before {
  content: "\e9a1";
}
.icon-trekking:before {
  content: "\e9a2";
}
.icon-urgent:before {
  content: "\e9a3";
}
.icon-user:before {
  content: "\e9a4";
}
.icon-user2:before {
  content: "\e9a5";
}
.icon-vineyard:before {
  content: "\e9a6";
}
.icon-voice:before {
  content: "\e9a7";
}
.icon-weather:before {
  content: "\e9a8";
}
.icon-wifi:before {
  content: "\e9a9";
}
.icon-wildlife:before {
  content: "\e9aa";
}
.icon-window:before {
  content: "\e9ab";
}
.icon-windy:before {
  content: "\e9ac";
}
.icon-zodiac-excursions:before {
  content: "\e9ad";
}